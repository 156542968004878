import React from "react";
import './MisionContent.css';

function MisionContent() {
  return (
    <div className="MisionContent">
        <div className="MisionC-TitleWrapper">
            
        </div>
        <div className="MisionC-MainContainer">
            <div className="MisionC-ContainerA">
              <h2>Visión</h2>
              <p>Lograr el posicionamiento de la empresa en la región y ante la comunidad. Siendo un prestador de servicios líder de alto rendimiento en su clase mediante el desarrollo y aplicación de tecnología de punta, fomento a la cultura de calidad, mediante la participación activa de sus integrantes logrando la excelencia en su personal y la máxima rentabilidad de su empresa </p>
            </div>
            <div className="MisionC-ContainerB">
              <h2>Misión</h2>
              <p>Llevar a excelente término todos y cada uno de los proyectos establecidos y por venir, cumpliendo con las normas de calidad establecidas por la empresa y por el cliente, demostrando que nuestro conocimiento y eficacia es el producto de años de experiencia en el ramo.</p>
            </div>
            <div className="MisionC-ContainerC">
              
            </div>
        </div>
    </div>
  );
}

export default MisionContent;