import React, { useState } from "react";
import './Header.css';
import MainLogo from './assets/img/M-L01.png';
import { Link } from 'react-router-dom';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="Header">
      <div className="Container-Header">
        <img src={MainLogo} alt="Inicio" className="logo" />
        
        {/* Botón para abrir/cerrar menú en mobile */}
        <button className="menu-toggle" onClick={toggleMenu}>
          {isMenuOpen ? "✖" : "☰"} {/* Cambia entre "abrir" y "cerrar" */}
        </button>

        {/* Menú principal */}
        <nav className={`menu ${isMenuOpen ? 'open' : ''}`}>
          <button>
            <Link to="/" className="Linkto" onClick={() => setIsMenuOpen(false)}>
              Inicio
            </Link>
          </button>
          <button>
            <Link to="/servicios" className="Linkto" onClick={() => setIsMenuOpen(false)}>
              Servicios
            </Link>
          </button>
          <button>
            <Link to="/experiencia" className="Linkto" onClick={() => setIsMenuOpen(false)}>
              Experiencia
            </Link>
          </button>
          <button>
            <Link to="/contacto" className="Linkto" onClick={() => setIsMenuOpen(false)}>
              Contacto
            </Link>
          </button>
          <button>
            <Link to="/login" className="Linkto" onClick={() => setIsMenuOpen(false)}>
              Certificados
            </Link>
          </button>
        </nav>
      </div>
    </div>
  );
};

export default Header;
