const eraTable = ({
    data,
    sortData,
    sortConfig,
    setData,
    setSortConfig,
    formatFecha,
    getSortIcon,
    renderSortButton,
    DownloadButton,
    getBackgroundColor
  }) => {

    const createSortButtonParams = (columnKey, label, type) => ({
        columnKey,
        label, 
        data: data,
        setData: setData,
        sortConfig: sortConfig,
        setSortConfig: setSortConfig,
        sortData:sortData,
        getSortIcon:getSortIcon,
        type
      });
  
      return (
        <>
        <h2>Tablas de Equipos de Respiración Autonomas</h2>
        <table className="content-table">
        <thead>
          <tr>
            <th rowSpan="2">Tag{renderSortButton(createSortButtonParams('id_internp', 'Tag'))}</th>
            <th rowSpan="2">Marca{renderSortButton(createSortButtonParams('marca', 'Tag'))}</th>
            <th rowSpan="2">Modelo{renderSortButton(createSortButtonParams('modelo', 'Tag'))}</th>
            <th colSpan="2">Numero de Serie</th>
            <th colSpan="3">Prueba Hidroestatica</th>
            <th colSpan="3">Recarga</th>
            
            
            <th rowSpan="2">PDF</th>
          </tr>
          <tr>
            <th>Cilindro{renderSortButton(createSortButtonParams('numero_serie_cilindro', 'Tag'))}</th>
            <th>Regulador{renderSortButton(createSortButtonParams('numero_serie_regulador', 'Tag'))}</th>
            <th>Última{renderSortButton(createSortButtonParams('ph', 'Tag', 'IsDate'))}</th>
            <th>Siguiente{renderSortButton(createSortButtonParams('ph_next', 'Tag', 'IsDate'))}</th>
            <th>Faltan{renderSortButton(createSortButtonParams('dias_restantes_1', 'Tag', 'IsNumeric'))}</th>
            <th>Última{renderSortButton(createSortButtonParams('ultima_recarga_revision', 'Tag', 'IsDate'))}</th>
            <th>Siguiente{renderSortButton(createSortButtonParams('recarga_next', 'Tag', 'IsDate'))}</th>
            <th>Faltan{renderSortButton(createSortButtonParams('dias_restantes_2', 'Tag', 'IsNumeric'))}</th>
          </tr>
        </thead>
        <tbody>
  {data.map((era, index) => (
    <tr key={index}>
      <td data-label="Tag">{era.id_interno}</td>
      <td data-label="Marca">{era.marca}</td>
      <td data-label="Modelo">{era.modelo}</td>
      <td data-label="N°S del Cilindro">{era.numero_serie_cilindro}</td>
      <td data-label="N°S del Regulador">{era.numero_serie_regulador}</td>
      <td data-label="Ult. PH">{formatFecha(era.ph)}</td>
      <td data-label="Sig. PH">{formatFecha(era.ph_next)}</td>
      <td
                  data-label="Dias faltantes"
                  style={{
                    backgroundColor: getBackgroundColor(era.dias_restantes_1),
                    color: 'white',
                  }}
                >
                  {era.dias_restantes_1 || 'N/A'} d
                </td>
      <td data-label="Ult.Recarga">{formatFecha(era.ultima_recarga_revision)}</td>
      <td data-label="Sig. Recarga">{formatFecha(era.recarga_next)}</td>
      <td
                  data-label="Dias faltantes"
                  style={{
                    backgroundColor: getBackgroundColor(era.dias_restantes_2),
                    color: 'white',
                  }}
                >
                  {era.dias_restantes_2 || 'N/A'} d
                </td>
      <td data-label="PDF">
      <DownloadButton table="eras" tag={era.id_interno} />
      </td>
    </tr>
  ))}
        </tbody>
        </table>
        </>
    );
  };
  
  export default eraTable;
