import React from 'react';

function MapIframe() {
  return (
    <div style={{ width: '100%', height: '450px' }}>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1683.8448871989758!2d-91.81866785539769!3d18.65933488957146!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTjCsDM5JzM0LjQiTiA5McKwNDknMDcuNiJX!5e0!3m2!1ses!2smx!4v1726327252249!5m2!1ses!2smx"
        width="100%"
        height="100%"
        style={{ border: '0' }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="Ubicación de BH Services de México" // Agrega el atributo title
      ></iframe>
    </div>
  );
}

export default MapIframe;