import React, { useState } from 'react';

const YesNoCheckbox = ({ onSelectionChange }) => {
  const [isYesSelected, setIsYesSelected] = useState(false); // Predeterminado: "No" seleccionado (false)
  

  const handleCheckboxChange = (option) => {
    const newSelection = option === 'yes';
    setIsYesSelected(newSelection);
    onSelectionChange(newSelection); // Notificar al componente padre con true/false
  };

  return (
    <div>
      <h3>¿Deseas modificarlo?</h3>
      <label>
        <input
          type="checkbox"
          checked={isYesSelected}
          onChange={() => handleCheckboxChange('yes')}
          style={{width:'auto' }}
        />
        Sí
      </label>
      <label style={{ marginLeft: '20px' }}>
        <input
          type="checkbox"
          checked={!isYesSelected}
          onChange={() => handleCheckboxChange('no')}
          style={{width:'auto' }}
        />
        No
      </label>
    </div>
  );
};

export default YesNoCheckbox;
