// Verificar si un archivo o carpeta existe en el servidor
const checkFileExists = async (table, tag) => {
    const url = `https://api.bhsmx.com/check-file/${table}/${tag}`;
  
    try {
        const response = await fetch(url, { method: 'GET' });
        const data = await response.json();

        if (response.ok) {
            return { exists: data.exists, message: data.message }; // Retornar estado y mensaje
        } else {
            console.error(`Error: ${data.message}`);
            return { exists: false, message: data.message }; // Retornar error como false
        }
    } catch (error) {
        console.error('Error al verificar el recurso:', error);
        return { exists: false, message: 'Error al verificar el recurso en el servidor.' };
    }
};

const downloadTool = async (table, tag, estrobo, grillete) => {
    const url = !estrobo || estrobo === "N/A" ? 
        (!grillete || grillete === "N/A" ?
            `https://api.bhsmx.com/download/${table}/${tag}` :
            `https://api.bhsmx.com/download/${table}/${tag}/${grillete}`) :
        `https://api.bhsmx.com/download/${table}/${tag}/${estrobo}${grillete ? `/${grillete}` : ""}`;

    try {
        // Verificar si el recurso principal existe
        const { exists, message } = await checkFileExists(table, tag);
        if (!exists) {
            console.warn(`El recurso no está disponible: ${message}`);
            return alert(message);
        }

        // Verificar si el estrobo existe si se proporcionó y no es "N/A"
        if (estrobo && estrobo !== "N/A") {
            const { exists: estroboExists, message: estroboMessage } = await checkFileExists('estrobos', estrobo);
            if (!estroboExists) {
                console.warn(`El recurso del estrobo no está disponible: ${estroboMessage}`);
                alert(`El archivo del estrobo (${estrobo}) no está disponible. Continuando con la descarga principal.`);
            }
        }

        // Verificar si el grillete existe si se proporcionó y no es "N/A"
        if (grillete && grillete !== "N/A") {
            const { exists: grilleteExists, message: grilleteMessage } = await checkFileExists('grilletes', grillete);
            if (!grilleteExists) {
                console.warn(`El recurso del grillete no está disponible: ${grilleteMessage}`);
                alert(`El archivo del grillete (${grillete}) no está disponible. Continuando con la descarga principal.`);
            }
        }

        // Descargar el archivo
        const response = await fetch(url, { method: 'GET' });
        if (response.ok) {
            const blob = await response.blob();
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${tag}.zip`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(link.href);
        } else {
            console.error('Error al descargar el archivo:', response.statusText);
            alert('Error al intentar descargar el recurso.');
        }
    } catch (error) {
        console.error('Error en la solicitud de descarga:', error);
        alert('Ocurrió un error inesperado al descargar el recurso.');
    }
};


export { checkFileExists, downloadTool };
