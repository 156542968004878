import React from "react";
import './CompaniesCarousel.css';




function CompaniesCarousel({images}) {
  return (
    <div className="Container-Companies">
      <h2>Nuestros Clientes</h2>
      <div className="companies-carousel">
        <div className="companies-item">
          {images.concat(images).map((src, index) => (
            <div className="carousel-item" key={index}>
            <img src={src} alt={`Company ${index + 1}`} />
            </div>
          ))}
        </div>
      </div>

    </div>

  );
}

export default CompaniesCarousel;
