import './BombeoHorizontal.css';
import SelectContentBox from './SelectContentBox';
import img1 from './assets/img/M-G023.jpg';
import img2 from './assets/img/M-G032.jpg';
import img3 from './assets/img/M-G031.jpg';
import img4 from './assets/img/M-G012.jpg';
import img5 from './assets/img/M-G010.jpg';
import img6 from './assets/img/M-G069.jpg';
import img7 from './assets/img/M-G035.jpg';
import img8 from './assets/img/M-G042.jpg';


const items=[
    { title: 'Ingeniería: Planos y Memoria de Cálculo del Sistema Eléctrico', img: [img1], text: '<p>La ingeniería es un componente esencial en el desarrollo de proyectos eléctricos, ya que asegura que cada aspecto del sistema sea diseñado, planificado y documentado de manera precisa.</p><p>Los planos son representaciones gráficas detalladas del sistema eléctrico, que incluyen la disposición de los equipos, el cableado, las conexiones y los componentes del sistema. Estos planos son cruciales para la correcta instalación y operación del sistema, ya que proporcionan una guía clara para el equipo de instalación y mantenimiento.</p><p>Por otro lado, la memoria de cálculo del sistema eléctrico es un documento técnico que detalla los cálculos realizados para dimensionar adecuadamente los componentes eléctricos, como conductores, transformadores y dispositivos de protección. Esta memoria incluye análisis de carga, caídas de tensión y selección de equipos, garantizando que el sistema cumpla con las normativas y estándares de seguridad.</p><p>Ambos elementos son fundamentales para asegurar que el sistema eléctrico opere de manera eficiente, segura y confiable, proporcionando un soporte sólido para las operaciones industriales y minimizando riesgos.</p>'},
    { title: 'Fabricación: Construcción de líneas de succión y descarga hasta ducto disponible', img: [img6 ], text: 'Este proceso involucra la creación y ensamblaje de tuberías especializadas que permiten el flujo de fluidos entre el sistema de bombeo (HPS) y los ductos disponibles en la instalación. La fabricación incluye el corte, soldadura y ensamblaje de materiales resistentes a la corrosión y presión, asegurando que las líneas de succión capturen eficientemente el fluido y las líneas de descarga lo transporten hacia el ducto de destino sin pérdidas ni interferencias. Todo el proceso se realiza conforme a las normas de seguridad y calidad industrial para garantizar un funcionamiento óptimo.'},
    { title: 'Instalación del SKID de HPS', img: [img4, img5], text: '<p>La instalación del SKID de HPS es un proceso crítico que implica la colocación y aseguramiento de la unidad de bombeo en su ubicación designada dentro de la planta o instalación. Este procedimiento incluye la nivelación del SKID para garantizar un funcionamiento eficiente, así como la conexión de todos los componentes hidráulicos y eléctricos necesarios.</p><p>Se verifican las conexiones de entrada y salida de fluidos, asegurando que estén bien selladas y alineadas con las líneas de succión y descarga previamente instaladas. También se realiza la interconexión eléctrica entre el SKID, los sistemas de control y generación de energía, como los variadores de frecuencia (VDF). Durante esta instalación, se siguen rigurosos protocolos de seguridad y calidad, asegurando que todos los componentes funcionen correctamente y que el sistema esté listo para su operación efectiva. Al finalizar, se lleva a cabo una inspección completa para confirmar la integridad de todas las conexiones y el cumplimiento de los estándares establecidos.</p>'},
    { title: 'Instalación eléctrica', img: [img1,img2], text: '<p>La instalación eléctrica para la interconexión de los equipos de generación, control (Variadores de Frecuencia - VDF) y sistemas de bombeo electrocentrífugo (HPS) es un proceso crítico que garantiza un funcionamiento eficiente y seguro del sistema. Este proceso incluye varias etapas:</p><ul>Planificación: Se desarrolla un plan detallado que incluye la disposición de los equipos, la selección de los componentes eléctricos adecuados y el diseño de la red de cableado.</ul><ul>Cableado: Se realiza la instalación de conductores eléctricos que conectan los equipos de generación a los VDF y a los sistemas HPS. Este cableado debe cumplir con las normativas vigentes y considerar aspectos como la capacidad de corriente y las distancias de instalación.</ul><ul>Conexiones: Se efectúan las conexiones necesarias entre los equipos, asegurando que todos los terminales y puntos de interconexión estén correctamente conectados y etiquetados para facilitar futuras operaciones y mantenimiento.</ul><ul>Integración de Control: Los VDF se conectan al sistema de control para permitir la gestión y optimización de la velocidad y el par de los motores, mejorando así la eficiencia del sistema de bombeo.</ul><ul>Verificación y Pruebas: Una vez completada la instalación, se llevan a cabo pruebas eléctricas para verificar la integridad de las conexiones y el funcionamiento adecuado de todos los sistemas interconectados.</p><p>Este proceso asegura que los equipos operen de manera sincronizada, optimizando el rendimiento y garantizando la seguridad en su funcionamiento.</p>'},
    { title: 'Instalación de líneas de proceso', img: [img3, img7, img8], text: '<p>La instalación de líneas de proceso es un componente crítico en la implementación de un sistema de bombeo electrocentrífugo (HPS). Este proceso implica la conexión de las tuberías que transportan el fluido desde la fuente hasta el sistema de bombeo y, posteriormente, hacia el ducto de descarga.</p><p>El proceso comienza con la planificación y diseño de las líneas de proceso, considerando factores como la presión, el tipo de fluido y las características del terreno. Se utilizan materiales adecuados, como tuberías de acero o PVC, que cumplen con los estándares de calidad y resistencia.</p><p>Durante la instalación, se realiza la colocación de las líneas de succión y descarga, asegurando que estén correctamente alineadas y fijadas para evitar fugas y garantizar un flujo óptimo. Se instalan también válvulas de control y accesorios necesarios para facilitar el manejo del sistema y permitir el mantenimiento futuro.</p><p>Una vez finalizada la instalación, se llevan a cabo pruebas de presión y funcionalidad para verificar la integridad de las líneas y asegurar que el sistema opere de manera eficiente. Esta instalación es fundamental para garantizar el rendimiento y la efectividad del HPS en la manipulación de fluidos en diversas aplicaciones industriales.</p>'}
]

function BombeoHorizontal() {
  return (
   <div className='Container-BombeoHorizontal'>
    <h2>Instalación de Sistemas de Bombeo Horizontales</h2>
    <SelectContentBox  
        items={items}
        />
   </div>
  );
}

export default BombeoHorizontal;
