import React, { useState, useEffect } from 'react';
import './Certificados.css';

// ICONS
import SortZa from './assets/icon/SortAz.svg';
import SortAz from './assets/icon/SortZa.svg';

// UTILITY FUNCTIONS
import sortData from './api/SortUtility.js'; // Función para ordenar datos
import renderSortButton from './api/renderSortButton'; // Función para renderizar botones de ordenamiento
import DownloadButton from './DownloadButton'
import getBackgroundColor from './api/getBackgroundColor'

// TABLE COMPONENTS
import BmlTable from './api/Tablas/bml.js';
import ExtintoresTable from './api/Tablas/extintores.js';
import EraTable from './api/Tablas/eras';
import SpoolersTable from './api/Tablas/spoolers';
import Base_CTable from './api/Tablas/baseC';
import CanastillasTable from './api/Tablas/canastillas';
import CajaHerTable from './api/Tablas/cajasHer';
import RacksTable from './api/Tablas/racks';
import TorquimetrosTable from './api/Tablas/torquimetros';

const Certificados = () => {
  // Estados para cada tabla y configuración de ordenamiento
  const [eras, setEras] = useState([]);
  const [sortConfigEras, setSortConfigEras] = useState({ key: null, direction: 'ascending' });

  const [extintores, setExtintores] = useState([]);
  const [sortConfigExtintores, setSortConfigExtintores] = useState({ key: null, direction: 'ascending' });

  const [Bml, setBml] = useState([]);
  const [sortConfigBml, setSortConfigBml] = useState({ key: null, direction: 'ascending' });

  const [Spoolers, setSpoolers] = useState([]);
  const [sortConfigSpoolers, setSortConfigSpoolers] = useState({ key: null, direction: 'ascending' });

  const [Base_C, setBase_C] = useState([]);
  const [sortConfigBase_C, setSortConfigBase_C] = useState({ key: null, direction: 'ascending' });

  const [Caja_Her, setCaja_Her] = useState([]);
  const [sortConfigCaja_Her, setSortConfigCaja_Her] = useState({ key: null, direction: 'ascending' });

  const [Canastillas, setCanastillas] = useState([]);
  const [sortConfigCanastillas, setSortConfigCanastillas] = useState({ key: null, direction: 'ascending' });

  const [Racks, setRacks] = useState([]);
  const [sortConfigRacks, setSortConfigRacks] = useState({ key: null, direction: 'ascending' });

  const [Torquimetros, setTorquimetros] = useState([]);
  const [sortConfigTorquimetros, setSortConfigTorquimetros] = useState({ key: null, direction: 'ascending' });

  // Función para obtener datos de la API y calcular los días restantes para campos específicos
  const fetchDataWithDays = async (url, setData, dateFields) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Error en la solicitud: ${response.statusText}`);
      }
      const data = await response.json();
  
      // Procesar los datos para añadir días restantes
      const processedData = data.map((item) => {
        const hoy = new Date();
        const daysRemaining = {};
  
        // Asignar índices para los campos de fecha
        dateFields.forEach((field, index) => {
          if (item[field]) {
            const diferencia = new Date(item[field]) - hoy;
            // Generar campo con sufijo numérico
            daysRemaining[`dias_restantes_${index + 1}`] = Math.ceil(diferencia / (1000 * 60 * 60 * 24));
          } else {
            daysRemaining[`dias_restantes_${index + 1}`] = null;
          }
        });
  
        return { ...item, ...daysRemaining };
      });
  
      setData(processedData); // Actualizar el estado con los datos procesados
    } catch (error) {
      console.error(`Error al obtener los datos de ${url}:`, error);
    }
  };
  
  // Efecto para cargar los datos al montar el componente
  useEffect(() => {
    fetchDataWithDays('https://api.bhsmx.com/api/eras', setEras, ['ph_next','recarga_next']);
    fetchDataWithDays('https://api.bhsmx.com/api/extintores', setExtintores, ['fecha_vencimiento']);
    fetchDataWithDays('https://api.bhsmx.com/api/spoolers', setSpoolers, ['carga_next', 'pnd_next', 'mtto_next']);
    fetchDataWithDays('https://api.bhsmx.com/api/bml', setBml, ['carga_next', 'pnd_next']);
    fetchDataWithDays('https://api.bhsmx.com/api/base_c', setBase_C, ['carga_next', 'pnd_next']);
    fetchDataWithDays('https://api.bhsmx.com/api/cajas_her', setCaja_Her, ['carga_next', 'pnd_next']);
    fetchDataWithDays('https://api.bhsmx.com/api/canastillas', setCanastillas, ['carga_next', 'pnd_next']);
    fetchDataWithDays('https://api.bhsmx.com/api/racks', setRacks, ['next_certificacion']);
    fetchDataWithDays('https://api.bhsmx.com/api/torquimetros', setTorquimetros, ['calibracion_next']);
  }, []);

  // Función para obtener el icono de ordenamiento según la configuración
  const getSortIcon = (sortConfig, key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? SortAz : SortZa;
    }
    return SortAz; // Ícono por defecto
  };

  // Función para formatear la fecha en formato local
  const formatFecha = (fecha) => {
    const date = new Date(fecha);
    return date.toLocaleDateString(); // Devuelve solo la fecha
  };

  // Configuración para las tablas
  const tableComponents = [
    { Component: EraTable, data: eras, sortConfig: sortConfigEras, setData: setEras, setSortConfig: setSortConfigEras },
    { Component: ExtintoresTable, data: extintores, sortConfig: sortConfigExtintores, setData: setExtintores, setSortConfig: setSortConfigExtintores },
    { Component: BmlTable, data: Bml, sortConfig: sortConfigBml, setData: setBml, setSortConfig: setSortConfigBml },
    { Component: SpoolersTable, data: Spoolers, sortConfig: sortConfigSpoolers, setData: setSpoolers, setSortConfig: setSortConfigSpoolers },
    { Component: Base_CTable, data: Base_C, sortConfig: sortConfigBase_C, setData: setBase_C, setSortConfig: setSortConfigBase_C },
    { Component: CajaHerTable, data: Caja_Her, sortConfig: sortConfigCaja_Her, setData: setCaja_Her, setSortConfig: setSortConfigCaja_Her },
    { Component: CanastillasTable, data: Canastillas, sortConfig: sortConfigCanastillas, setData: setCanastillas, setSortConfig: setSortConfigCanastillas },
    { Component: RacksTable, data: Racks, sortConfig: sortConfigRacks, setData: setRacks, setSortConfig: setSortConfigRacks },
    { Component: TorquimetrosTable, data: Torquimetros, sortConfig: sortConfigTorquimetros, setData: setTorquimetros, setSortConfig: setSortConfigTorquimetros },
  ];

  // Renderizado de las tablas dinámicamente
  return (
    <div className="TablaEras-Container">
      {tableComponents.map(({ Component, data, sortConfig, setData, setSortConfig }) => (
        <Component
          key={Component.name} // Usar el nombre del componente como clave
          data={data}
          sortConfig={sortConfig}
          setData={setData}
          setSortConfig={setSortConfig}
          sortData={sortData}
          formatFecha={formatFecha}
          getSortIcon={getSortIcon}
          renderSortButton={renderSortButton}
          DownloadButton={DownloadButton}
          getBackgroundColor={getBackgroundColor}
        />
      ))}
    </div>
  );
};

export default Certificados;


