import React, { useState } from "react";
import './ContainerExpCompania.css';
import FocusImg from './assets/img/M-PC002.jpg';
import FocusImg1 from './assets/img/M-G042.jpg';
import FocusImg2 from './assets/img/SP-008.jpg';
import FocusImg3 from './assets/img/SP-004.png';
import FocusImg4 from './assets/img/M-G042.jpg';
import FocusImg5 from './assets/img/SP-008.jpg';
import FocusImg6 from './assets/img/SP-004.png';
import FocusImg7 from './assets/img/VDF-006.jpg';
import FocusImg8 from './assets/img/SP-008.jpg';
import FocusImg9 from './assets/img/M-G064.jpg';
import FocusImg10 from './assets/img/M-G042.jpg';
import FocusImg11 from './assets/img/SP-008.jpg';
import FocusImg12 from './assets/img/SP-004.png';

import company1 from './assets/img/IMG-C-01.jpg';
import company2 from './assets/img/IMG-C-02.jpg';
import company3 from './assets/img/IMG-C-11.jpg';
import company4 from './assets/img/IMG-C-13.jpg';
import company5 from './assets/img/IMG-C-06.jpg';
import company6 from './assets/img/IMG-C-07.jpg';
import company7 from './assets/img/IMG-C-17.jpg';
import company8 from './assets/img/IMG-C-14.png';  
import company9 from './assets/img/IMG-C-10.jpg';
import company10 from './assets/img/IMG-C-12.png';
import company12 from './assets/img/IMG-C-16.jpg';

const imagesCompanies = [
    { img: company1, img2: FocusImg1, titulo: "SHLUMBERGER - SLB", descripcion: "<ul>Instalación de Variadores de Frecuencia (VDF) de Media Tensión</ul><ul>Sistemas BEC (Bombeo Electrocentrífugo)</ul><ul>Instalación de HPS</ul><ul>Instalación y Mantenimiento de Charolas</ul><ul>Fabricación, Renta y Mantenimiento de Spoolers</ul>" },
    { img: company2, img2: FocusImg2, titulo: "BAKER HUGHES", descripcion: "<ul>Instalación de Equipos BEC</ul><ul>Instalación de Variadores de Frecuencia (VDF) de Media Tensión</ul><ul>Instalación de Bombas Horizontales</ul><ul>Fabricación y Mantenimiento de Equipos Spooler</ul>" },
    { img: company3, img2: FocusImg3, titulo: "ALKHORAYEF", descripcion: "<ul>Instalación y Servicio Técnico en Instalación de Aparejo BEC</ul><ul>Fabricación de 4 Skid Portátil para Equipo BEC Superficial.</ul><ul>Servicio Técnico y Suministro de Equipos para Intervención de Pozo</ul><ul>Fabricación de Spooler Electrohidraulico</ul>" },
    { img: company4, img2: FocusImg, titulo: "SEPARMEX", descripcion: "<ul>Instalación de Grúas Viajeras, Grúas Tipo Bandera y de Secadora de Aire</ul><ul>Instalación de Centrifugadora</ul>" },
    { img: company5, img2: FocusImg, titulo: "GEOLIS", descripcion: "<ul>Servicio Técnico para Instalación de Sensor de Fondo en Pozo.</ul>" },
    { img: company6, img2: FocusImg, titulo: "SOLAR", descripcion: "<ul>Fabricación de Ductos de Escape para Motogeneradores.</ul>" },
    { img: company7, img2: FocusImg7, titulo: "HALVORSEN", descripcion: "<ul>Instalación de Variador de Frecuencia de Media Tensión marca Siemens.</ul><ul>Apoyo Técnico en Comisionamiento de Variadores de Frecuencia Marca Siemens</ul>" },
    { img: company8, img2: FocusImg, titulo: "AGGREKO", descripcion: "<ul>Servicio Técnico para Monitoreo de Generadores Eléctricos.</ul>" },
    { img: company9, img2: FocusImg9, titulo: "PETROFOR", descripcion: "<ul>Rehabilitación de SKID con Equipo BEC Superficial</ul>" },
    { img: company10, img2: FocusImg, titulo: "MC-DERMONT", descripcion: "<ul>Instalación de Terminales Termocontractil a Cables en Cuarto de Control.</ul>" },
    { img: company12, img2: FocusImg, titulo: "UNICORNIO", descripcion: "<ul>Instalación y Servicio Técnico de Llaves de Piso Automatizadas</ul>" },
];

function ContainerExpCompania() {
  const [activeIndex, setActiveIndex] = useState(0); // Estado para la imagen activa

  const handleImageClick = (index) => {
    setActiveIndex(index); // Cambiar la imagen activa
  };

  return (
    <div className="container-ContainerExpCompania">
        <div className="container-CExpC-Top">
            {imagesCompanies.map((company, index) => (
                <img 
                    key={index} 
                    src={company.img} 
                    alt={`CExpC-Buttonimg ${index + 1}`} 
                    className={`CExpC-Buttonimg ${activeIndex === index ? 'active' : ''}`} // Añadir clase activa
                    onClick={() => handleImageClick(index)} // Manejador de clic
                />
            ))}
        </div>
        <div className="container-CExpC-Bot">
            <div className="container-CExpC-BotA">
                <img src={imagesCompanies[activeIndex].img2} alt="Imagen de enfoque" className="CExpC-FocusImg" />
            </div>
            <div className="container-CExpC-BotB">
                <h2>{activeIndex !== null ? imagesCompanies[activeIndex].titulo : ''}</h2>
                <div className="CExpC-BotB-text">
                    <p dangerouslySetInnerHTML={{ __html: activeIndex !== null ? imagesCompanies[activeIndex].descripcion : '' }} />
                </div>
            </div>
        </div>
    </div>
  );
}

export default ContainerExpCompania;