import React from "react";
import './Footer.css';
import MainLogo from './assets/img/M-L01.png';

function Footer() {
  return (
    <div className="Container-Footer">
        <div className="Container-Footer-A">
            <div className="Wrap-Footer">
                <img src={MainLogo} alt="Logo BH-Services" className="Footer-Logo"/>
            </div>
            <div className="Footer-text">
            <div className="Container-Footer-Text">
            <li>
                <ul className="F-izq">
                  <p>Numeros de telefono:</p>
                </ul>
                <ul className="F-der">
                  <p>(+52)  938-168-2704 </p>
                  <p>(+52)  938-104-9498 </p>
                  <p>(+52)  993-303-0590 </p>
                </ul>
            </li>
            <li>
                <ul className="F-izq">
                  <p>Correo:</p>
                </ul>
                <ul className="F-der">
                <a href="mailto:contacto@bhservicesdemexico.com"><p>contacto@bhservicesdemexico.com</p></a>
                 
                </ul>
            </li>
            <li>
                <ul className="F-izq">
                  <p>Dirección: </p>
                </ul>
                <ul className="F-der">        
                  <p>Calle 53-A #346 Entre Calle 70-A y Av. Périferica Norte. CP:24115 Colonia: Morelos. Ciudad del Carmen, Campeche.</p>
                </ul>
            </li>

            </div>
            </div>
        </div>
        <div className="Container-Footer-B">
            <div className="Footer-text-B">
                <p>© 2024 BH Services de México S.A de C.V </p>
            </div>
        </div>
    </div>
  );
}

export default Footer;