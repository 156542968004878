import React, { useState, useEffect } from "react";
import './TwoSidesContent.css'
import VideoReproductor from "./VideoReproductor";

function TwoSidesContent({ images1, images2 }) {
  const [currentIndex1, setCurrentIndex1] = useState(0);
  const [currentIndex2, setCurrentIndex2] = useState(0);

  // useEffect para cambiar la imagen de images1 cada 5 segundos
  useEffect(() => {
    const interval1 = setInterval(() => {
      setCurrentIndex1((prevIndex) => (prevIndex + 1) % images1.length);
    }, 4000); // Cambia cada 5 segundos

    return () => clearInterval(interval1); // Limpia el intervalo al desmontar el componente
  }, [images1.length]);

  // useEffect para cambiar la imagen de images2 con un retraso de 1s
  useEffect(() => {
    const interval2 = setInterval(() => {
      setCurrentIndex2((prevIndex) => (prevIndex + 1) % images2.length);
    }, 5000); // Cambia también cada 5 segundos
    return () => clearInterval(interval2); // Limpia el intervalo al desmontar el componente
  }, [images2.length]);

  return (
    <div className="Container-TSC">
      <h1>B.H Services de México S.A de C.V</h1>

      <div className="Container-FS">
        <div className="FS-text">
          <h2>{images1[0].subtitle}</h2>
          <p>{images1[0].text}</p>
        </div>
        <img
          src={images1[currentIndex1].src}
          alt={images1[currentIndex1].description}
          className="FS-img"
        />
      </div>

      <div className="Container-SS">
          <VideoReproductor/>
        <div className="SS-text">
          <h2>{images2[0].subtitle}</h2>
          <p>{images2[0].text}</p>
        </div>
      </div>
    </div>
  );
}

export default TwoSidesContent;