import React, { useState, useEffect } from 'react';
import { checkFileExists, downloadTool } from './api/downloadtool';
import DLicon from './assets/icon/Download.svg';

const DownloadButton = ({ table, tag, estrobo, grillete }) => {
  const [fileExists, setFileExists] = useState(false);

  // Verificar si el archivo existe al montar el componente
  useEffect(() => {
    const verifyFile = async () => {
      const { exists } = await checkFileExists(table, tag);
      setFileExists(exists); // Actualizar el estado según el resultado
    };

    verifyFile();
  }, [table, tag]);

  return (
    fileExists && ( // Mostrar el botón solo si el archivo existe
      <button
        className="DownloadButton"
        onClick={() => downloadTool(table, tag, estrobo, grillete)}
      >
        <img src={DLicon} alt="Download" />
      </button>
    )
  );
};

export default DownloadButton;
