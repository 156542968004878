import React, { useState, useEffect } from 'react';
import './TopSelectorbySides.css';

function TopSelectorbySides({ title, namesV }) {
  const [activeIndex, setActiveIndex] = useState(0); // Índice de opción seleccionada
  const [imageIndex, setImageIndex] = useState(0);   // Índice de imagen dentro del array (si es un array)
  const [isMobile, setIsMobile] = useState(false);   // Verifica si está en móvil

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Llama a la función inicialmente
    window.addEventListener('resize', handleResize); // Actualiza en cambios de tamaño

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const activeItem = namesV[activeIndex]; // El ítem activo actual

    if (Array.isArray(activeItem.img)) {
      const interval = setInterval(() => {
        setImageIndex((prevIndex) => (prevIndex + 1) % activeItem.img.length); // Cambiar entre imágenes
      }, 5000);

      return () => clearInterval(interval); // Limpiar el intervalo
    } else {
      setImageIndex(0); // Reiniciar el índice de imagen a 0 si no es un array
    }
  }, [activeIndex, namesV]);

  const activeItem = namesV[activeIndex];
  const currentImg = Array.isArray(activeItem.img) ? activeItem.img[imageIndex] : activeItem.img;

  return (
    <div className="Container-TopSelectorbySides">
      <h2>{title}</h2>

      {/* Comportamiento según dispositivo */}
      {!isMobile ? (
        <div className="Container-TSSTopSide">
          {namesV.map((item, index) => (
            <div className="TopSide-items" key={index}>
              <button
                className={`TopSideButton ${activeIndex === index ? 'active' : ''}`}
                onClick={() => setActiveIndex(index)}
              >
                <h3>{item.name}</h3>
              </button>
            </div>
          ))}
        </div>
      ) : (
        <div className="dropdown-menu">
          <select
            className="dropdown-select"
            value={activeIndex}
            onChange={(e) => setActiveIndex(parseInt(e.target.value))}
          >
            {namesV.map((item, index) => (
              <option key={index} value={index}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
      )}

      <div className="Container-TSSBotSide">
        <img src={currentImg} alt={activeItem.name} className="Img-TSbS" />
        <div className="TSSBotSide-text">
          <h3>{activeItem.name}</h3>
          <p dangerouslySetInnerHTML={{ __html: activeItem.description }} />
        </div>
      </div>
    </div>
  );
}

export default TopSelectorbySides;
