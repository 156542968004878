import React, { useState, useEffect } from 'react';
import './SelectContentBox.css';

const SelectButton = ({ text, isActive, onClick }) => (
  <button 
    className={isActive ? 'active' : ''} 
    onClick={onClick} 
    aria-pressed={isActive} // Añade accesibilidad
  >
    <h3>{text}</h3>
  </button>
);

function SelectContentBox({ items }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeImageIndex, setActiveImageIndex] = useState(0); // Índice de la imagen actual

  const handleButtonClick = (index) => {
    setActiveIndex(index);
    setActiveImageIndex(0); // Reinicia la imagen al cambiar de opción
  };

  useEffect(() => {
    const images = items[activeIndex].img;
    if (Array.isArray(images) && images.length > 1) {
      const interval = setInterval(() => {
        setActiveImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 5000); // Cambia la imagen cada 5 segundos

      return () => clearInterval(interval); // Limpia el intervalo cuando el componente se desmonta o cambia de imagen
    }
  }, [activeIndex, items]); // Ejecuta el efecto cada vez que se cambia de índice

  return (
    <div className='SelectCB-AllContainer'>
      <div className="Container-SelectCB">
        <div className="Container-SCB">
          <div className="Container-SCB-A">
            {items.map((item, index) => (
              <SelectButton
                key={index}
                text={item.title}
                isActive={activeIndex === index}
                onClick={() => handleButtonClick(index)}
              />
            ))}
          </div>
          <div className="Container-SCB-B">
            {/* Verifica si hay múltiples imágenes y cambia entre ellas */}
            {Array.isArray(items[activeIndex].img) ? (
              <img 
                src={items[activeIndex].img[activeImageIndex]} 
                alt={`${items[activeIndex].title} - Image ${activeImageIndex + 1}`} 
                className="SCB-img" 
              />
            ) : (
              <img 
                src={items[activeIndex].img} 
                alt={items[activeIndex].title} 
                className="SCB-img" 
              />
            )}
          </div>
        </div>
        <div className="Container-SCB-C">
         
        </div>
      </div>
      <div className='Container-SCB-Bot-B'>
        {/* Renderiza el texto HTML si existe */}
        {items[activeIndex].text && (
          <p dangerouslySetInnerHTML={{ __html: items[activeIndex].text }} />
        )}
      </div>
    </div>
  );
}

export default SelectContentBox;

