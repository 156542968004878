const ExtintoresTable = ({
    data,
    sortData,
    sortConfig,
    setData,
    setSortConfig,
    formatFecha,
    getSortIcon,
    renderSortButton,
    DownloadButton,
    getBackgroundColor
  }) => {

    const getBackgroundColor2 = (Estado) =>
    Estado === true ? '#6BAF59' : '#D9534F';
      
    
    const createSortButtonParams = (columnKey, label, type) => ({
        columnKey,
        label,
        data: data,
        setData: setData,
        sortConfig: sortConfig,
        setSortConfig: setSortConfig,
        sortData:sortData,
        getSortIcon:getSortIcon,
        type
      });
  
    return (
<>
      <h2>Tabla de Extintores</h2>
      <table className="content-table">
        <thead>
          <tr>
          <th rowSpan="2">Tag{renderSortButton(createSortButtonParams('id_interno', 'Tag'))}</th>
            <th rowSpan="2">N° Serie{renderSortButton(createSortButtonParams('numero_serie', 'Tag'))}</th>
            <th rowSpan="2">Agente{renderSortButton(createSortButtonParams('agente', 'Tag'))}</th>
            <th rowSpan="2">Peso{renderSortButton(createSortButtonParams('peso', 'Tag', 'IsNumeric'))}</th>
            <th colSpan="3">Recarga</th>
            <th rowSpan="2">Estado{renderSortButton(createSortButtonParams('Estado', 'Tag', 'IsBoolean'))}</th>
            <th rowSpan="2">Ubicación{renderSortButton(createSortButtonParams('ubicacion', 'Tag'))}</th>
            <th rowSpan="2">PDF</th>
          </tr>
          <tr>
          <th>Ultima{renderSortButton(createSortButtonParams('fecha_recarga', 'Tag', 'IsDate'))}</th>
          <th>Siguiente{renderSortButton(createSortButtonParams('fecha_vencimiento', 'Tag', 'IsDate'))}</th>
          <th>Dias{renderSortButton(createSortButtonParams('dias_restantes', 'Tag','IsNumeric'))}</th>
            
          </tr>
        </thead>
        <tbody>
        {data.map((extintor, index) => (
    <tr key={index}>
      <td data-label="Tag">{extintor.id_interno}</td>
      <td data-label="N° Serie">{extintor.numero_serie}</td>
      <td data-label="Agente">{extintor.agente}</td>
      <td data-label="Peso">{extintor.peso} Kg</td>
      <td data-label="Ultima">{formatFecha(extintor.fecha_recarga)}</td>
      <td data-label="Siguiente">{formatFecha(extintor.fecha_vencimiento)}</td>
      <td data-label="Dias faltantes" 
                  style={{
                    backgroundColor: getBackgroundColor(extintor.dias_restantes_1),
                    color: 'white',
                  }}> {extintor.dias_restantes_1} d</td>
                        <td
  data-label="Estado"
  style={{
    backgroundColor: getBackgroundColor2(extintor.Estado),
    color: 'white',
  }}
>
  {extintor.Estado ? "Recargado" : "Descargado"}
</td>
      <td data-label="Ubicación">{extintor.Ubicacion}</td>

      <td data-label="PDF">
      <DownloadButton table="extintores" tag={extintor.id_interno} />
      </td>
    </tr>
          ))}
        </tbody>
      </table>
</>
    );
};
export default ExtintoresTable;
