import React from "react";
import ContainerExpCompania from './ContainerExpCompania.js'

import './Experiencia.css';
import ExpandibleCards from "./ExpandibleCards.js";

function Experiencia() {
  return (
    <div className="container-experiencia">
        <div className="Experiencia-Wrapper">        
          <h2>Nuestra Experiencia en Proyectos</h2>
        </div>
        <div className="Exp-FirstContent">
          <ExpandibleCards/>
        </div>
        <div className="Exp-SecondContent">
          <ContainerExpCompania/>
        </div>
    </div>

  )
}

export default Experiencia;
