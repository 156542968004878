import React, { useState, useEffect, useCallback } from "react";
import './GaleryViewer.css';
import LeftIcon from './assets/icon/Left.svg';
import RightIcon from './assets/icon/Right.svg';

const GaleryViewer = ({ images }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [fade, setFade] = useState(false);

    const nextImage = useCallback(() => {
        setFade(true); // Inicia el desvanecimiento
        setTimeout(() => {
            setCurrentImageIndex((prevIndex) =>
                prevIndex === images.length - 1 ? 0 : prevIndex + 1
            );
            setFade(false); // Restablece la opacidad
        }, 500); // Tiempo que dura la transición (0.5s)
    }, [images.length]);

    const prevImage = useCallback(() => {
        setFade(true); // Inicia el desvanecimiento
        setTimeout(() => {
            setCurrentImageIndex((prevIndex) =>
                prevIndex === 0 ? images.length - 1 : prevIndex - 1
            );
            setFade(false); // Restablece la opacidad
        }, 500); // Tiempo que dura la transición (0.5s)
    }, [images.length]);

    // Cambia de imagen automáticamente cada 3.5 segundos
    useEffect(() => {
        const interval = setInterval(nextImage, 3500);
        return () => clearInterval(interval); // Limpia el intervalo cuando el componente se desmonta
    }, [nextImage]);

    return (
        <div className="Container-GaleryViewer">
            {/* Botón para retroceder */}
            <img
                src={LeftIcon}
                alt="Left"
                className="icon-left"
                onClick={prevImage}
            />
            
            {/* Imagen actual con clase para el efecto de desvanecimiento */}
            <div className={`img-Galery ${fade ? 'fade-out' : ''}`}>
                <img
                    src={images[currentImageIndex].src}
                    alt={images[currentImageIndex].description}
                />
            </div>

            {/* Botón para avanzar */}
            <img
                src={RightIcon}
                alt="Right"
                className="icon-right"
                onClick={nextImage}
            />
            
            {/* Indicador de página */}
            <div className="page-indicator">
                {currentImageIndex + 1}/{images.length}
            </div>
        </div>
    );
};

export default GaleryViewer;