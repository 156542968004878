import React from "react";
import ContentBoxFourSides from "./ContentBoxFourSides.js";
import TopSelectorbySides from "./TopSelectorbySides.js";
import './Servicios.css';

import BFSimg1 from "./assets/img/HPT-001.jpg";
import BFSimg2 from "./assets/img/VDF-005.jpg";
import BFSimg3 from "./assets/img/BEC-001.jpg";
import BFSimg4 from "./assets/img/SC-001.jpg";
import BFSimg5 from "./assets/img/M-G015.jpg";
import BFSimg6 from "./assets/img/M-G016.jpg";
import BFSimg7 from "./assets/img/M-G018.jpg";

import JSONBFSitems from './data/BFSitems.json';
import JSONSP from './data/dataSpooler.json';
import JSONDEstructuras from './data/dataEstructuras.json';

import SPimg001 from './assets/img/SP-004.png';
import SPimg002 from './assets/img/SP-002.jpg';
import SPimg003 from './assets/img/SP-007.png';
import SPimg004 from './assets/img/SP-006.jpg';
import SPimg005 from './assets/img/SP-005.jpg';
import SPimg006 from './assets/img/SP-001.jpg';
import SPimg007 from './assets/img/SP-008.jpg';

import SKD001 from './assets/img/M-G007.jpg';
import SKD002 from './assets/img/M-G012.jpg';
import SKD003 from './assets/img/M-G008.jpeg';
import SKD004 from './assets/img/M-G010.jpg';

import EV001 from './assets/img/M-G017.jpg';
import EV002 from './assets/img/BC-001.jpg';
import EV003 from './assets/img/CH-001.jpg';
import EV004 from './assets/img/MT-001.jpg';
import EV005 from './assets/img/M-G047.jpg';
import EV006 from './assets/img/M-G049.jpg';
import EV007 from './assets/img/CH-005.jpg';
import EV008 from './assets/img/CH-004.jpg';
import EV009 from './assets/img/CH-003.jpg';
import EV010 from './assets/img/CH-002.jpg';
import EV011 from './assets/img/CH-006.jpg';

const scrMap={
  "SPimg001": SPimg001,
  "SPimg002": SPimg002,
  "SPimg003": SPimg003,
  "SPimg004": SPimg004,
  "SPimg005": SPimg005,
  "SPimg006": SPimg006,
  "SPimg007" : SPimg007,
  "M-G007": SKD001,
  "M-G012" : SKD002,
  "M-G008": SKD003,
  "M-G010" : SKD004,
  "EV-001": EV001,
  "EV-002": EV005,
  "BC-001": EV002,
  "CH-001": EV003,
  "CH-002": EV006,
  "MT-001": EV004,
  "CH-003": EV007,
  "CH-004": EV008,
  "CH-005": EV009,
  "CH-006": EV010,
  "CH-007": EV011
};

const dataSpooler = JSONSP.map(item => ({
  ...item,
  // Verifica si 'img' es un array, de ser así, mapea cada imagen
  img: Array.isArray(item.img)
    ? item.img.map(imgKey => scrMap[imgKey])  // Mapea cada clave a su imagen correspondiente
    : scrMap[item.img]  // Si no es array, simplemente asigna la imagen correspondiente
}));

const dataEstructuras = JSONDEstructuras.map(item => ({
  ...item,
  // Verifica si 'img' es un array, de ser así, mapea cada imagen
  img: Array.isArray(item.img)
    ? item.img.map(imgKey => scrMap[imgKey])  // Mapea cada clave a su imagen correspondiente
    : scrMap[item.img]  // Si no es array, simplemente asigna la imagen correspondiente
}));



const imageMap = {
  "BFSimg1": BFSimg1,
  "BFSimg2": BFSimg2,
  "BFSimg3": BFSimg3,
  "BFSimg4": BFSimg4,
  "BFSimg5": BFSimg5,
  "BFSimg6": BFSimg6,
  "BFSimg7": BFSimg7,

};

const contentItems = JSONBFSitems.map(item => ({
  ...item,
  img: imageMap[item.img] // Asigna la imagen correspondiente
}));


function Servicios() {
  return (
    <div className="Container-Servicios">
      <div className="First-Content">
        <ContentBoxFourSides imgs={contentItems} />
      </div>

      <div className="Second-Content">
        <h2>Renta, Fabricación y Mantenimiento</h2>
        <TopSelectorbySides
          title={"Malacates para Enrollamiento de Cable (Spoolers)"}
          namesV={dataSpooler}
        />
      </div>
      <div className="Third-Content">
        <TopSelectorbySides
          title={"Estructuras Varias"}
          namesV={dataEstructuras}
        />
      </div>
    </div>
  );
}

export default Servicios;
