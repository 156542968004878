import React, { useState } from "react";
import './Bec.css';

import ContainerSlider from "./ContainerSlider";

import JSONMandriles from './data/Mandriles.json';
import JSONBEC from './data/bec.json';


import PC002 from './assets/img/M-PC002.jpg';
import SP001 from './assets/img/SP-001.jpg';
import SP007 from './assets/img/SP-007.png';
import MG070 from './assets/img/M-G070.jpg';
import MG071 from './assets/img/M-G071.jpg';

const imageMap = {
    "PC002": PC002,
    "SP-001": SP001,
    "SP-007": SP007,
    "MG-070" :MG070,
    "MG-071" :MG071
};


const MandrilesData = JSONMandriles.map(item => ({
    ...item,
    img: imageMap[item.img]  // Asigna la imagen correspondiente
  }));
  const BECData = JSONBEC.map(item => ({
    ...item,
    img: imageMap[item.img]  // Asigna la imagen correspondiente
  }));
  

function Bec() {
    const [activeIndex, setActiveIndex] = useState("0-0");
    return (
          <div className="Container-Bec">
            <ContainerSlider 
            titulo={"Instalación de Mandriles"}
            sliderData={MandrilesData}
             activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            sliderId={0}
        /> 
          <ContainerSlider 
            titulo={"Sistemas BEC"}
            sliderData={BECData}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            sliderId={1}
        /> 
          </div>
          
    );
  }
  
  export default Bec;
  