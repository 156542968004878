const renderSortButton = ({
    columnKey,
    label,
    data,
    setData,
    sortConfig,
    setSortConfig,
    sortData,
    getSortIcon,
    type
  }) => {
    return (
      <button
        className="Sort"
        onClick={() => sortData(data, setData, sortConfig, setSortConfig, columnKey, type)}
      >
        <img src={getSortIcon(sortConfig, columnKey)} alt={`Ordenar por ${label}`} />
      </button>
    );
  };
  
  export default renderSortButton;
  