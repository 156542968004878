import React from "react";
import './Contacto.css';
import MapIframe from './MapIframe';

function Contacto() {
  return (
    <div className="Container-Contacto">
      <div className="Container-Formulario">
      <div className="Form-Row">
        <ul>
          <p>Nombre</p>
          <input  
            type="text"
            id="nombre"
            name="nombre"
            required/>
        </ul>
        <ul>
          <p>Correo</p>
          <input  
            type="email"
            id="email"
            name="email"
            required/>
        </ul>
      </div>
      <ul>
        <p>Asunto</p>
        <input  
          type="text"
          id="asunto"
          name="asunto"
          required/>
      </ul>
      <ul>
        <p>Mensaje</p>
        <textarea
          id="mensaje"
          name="mensaje"
          rows="4"
          required/>
      </ul>
      <button className="submit-Contacto" type="submit">Enviar</button>
      
      </div>
      <div className="Container-OtrosMC">
        <div className="Container-Map">
          <MapIframe />
        </div>
        <div className="Contacto-Text">
        <h3>Datos de Contacto</h3>
            <div className="Container-Contacto-Text">
  
                <ul className="izq">
                  <p>Numeros de telefono:</p>
                </ul>
                <ul className="der">
                  <p>(+52)  938-168-2704 </p>
                  <p>(+52)  938-104-9498 </p>
                  <p>(+52)  993-303-0590 </p>
                </ul>
                <ul className="izq">
                  <p>Correo:</p>
                </ul>
                <ul className="der">
                  <a href="mailto:contacto@bhservicesdemexico.com"><p>contacto@bhservicesdemexico.com</p></a>
                </ul>
                <ul className="izq">
                  <p>Dirección: </p>
                </ul>
                <ul className="der">        
                  <p>Calle 53-A #346 Entre Calle 70-A y Av. Périferica Norte. CP:24115 Colonia: Morelos. Ciudad del Carmen, Campeche.</p>
                </ul>
            </div>
            
          </div>
      </div>
    </div>


    );
}

export default Contacto;