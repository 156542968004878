// Función para actualizar columnas
export const updateData = async (table, id_interno, newClient, newEstrobos, newGrilletes, oldEstrobos, oldGrilletes, newUbicacion, oldUbicacion) => {
  try {
    // Actualizar cliente (solo si hay nuevos clientes)
    if (newClient && newClient.length > 0) {
      await processEntries(newClient, async (client) => {
        await updateColumn({
          table, 
          column: 'cliente',
          id_interno,
          newValue: client,
          action: 'update',
        });
      });
    }

    // Eliminar estrobos antiguos (solo si hay valores antiguos)
    if (newEstrobos && oldEstrobos.length > 0) {
      await processEntries(oldEstrobos, async (estrobo) => {
        await updateColumn({
          table: 'estrobos',
          column: 'tag_equipo',
          id_interno: estrobo,
          action: 'delete',
        });
          await updateColumn({
            table: 'estrobos',
            column: 'ubicacion',
            id_interno: estrobo,
            action: 'delete',
          });
      });
    }
    // Asignar nuevos estrobos (solo si hay nuevos valores)
    if (newEstrobos && newEstrobos.length > 0) {
      await processEntries(newEstrobos, async (estrobo) => {
        await updateColumn({
          table: 'estrobos',
          column: 'tag_equipo',
          id_interno: estrobo,
          newValue: id_interno,
          action: 'update',
        });
        let ubicacion = oldUbicacion != newUbicacion ? oldUbicacion : newUbicacion
        await processEntries(ubicacion, async (ubicacion) => {
          await updateColumn({
            table: 'estrobos',
            column: 'ubicacion',
            id_interno: estrobo,
            newValue: ubicacion,
            action: 'update',
          });
        });
      });
    }

    // Eliminar grilletes antiguos (solo si hay valores antiguos)
    if (newGrilletes && oldGrilletes.length > 0) {
      await processEntries(oldGrilletes, async (grillete) => {
        await updateColumn({
          table: 'grilletes',
          column: 'tag_equipo',
          id_interno: grillete,
          action: 'delete',
        });
        await updateColumn({
          table: 'grilletes',
          column: 'ubicacion',
          id_interno: grillete,
          action: 'delete',
        });
      });
    }

    // Asignar nuevos grilletes (solo si hay nuevos valores)
    if (newGrilletes && newGrilletes.length > 0) {
      await processEntries(newGrilletes, async (grillete) => {
        await updateColumn({
          table: 'grilletes',
          column: 'tag_equipo',
          id_interno: grillete,
          newValue: id_interno,
          action: 'update',
        }); 
        let ubicacion = oldUbicacion != newUbicacion ? oldUbicacion : newUbicacion
        await processEntries(ubicacion, async (ubicacion) => {
          await updateColumn({
            table: 'grilletes',
            column: 'ubicacion',
            id_interno: grillete,
            newValue: ubicacion,
            action: 'update',
          });
        });
      });
    }
       // Asignar nuevos grilletes (solo si hay nuevos valores)
       if (newUbicacion && oldUbicacion.length > 0) {
        await processEntries(oldUbicacion, async (ubicacion) => {
          await updateColumn({
            table,
            column: 'ubicacion',
            id_interno,
            action: 'delete',
          });
          await processEntries(oldEstrobos, async (estrobo) => {
          await updateColumn({
            table: 'estrobos',
            column: 'ubicacion',
            id_interno: estrobo,
            action: 'delete',
          });
        });
        await processEntries(oldGrilletes, async (grillete) => {
          await updateColumn({
            table: 'grilletes',
            column: 'tag_equipo',
            id_interno: grillete,
            action: 'delete',
          });});
        });
      }
    // Asignar nuevos grilletes (solo si hay nuevos valores)
    if (newUbicacion && newUbicacion.length > 0) {
      await processEntries(newUbicacion, async (ubicacion) => {
        await updateColumn({
          table,
          column: 'ubicacion',
          id_interno,
          newValue: ubicacion,
          action: 'update',
        });
        let estrobos = oldEstrobos != newEstrobos ? oldEstrobos : newEstrobos
        await processEntries(estrobos, async (estrobo) => {
          await updateColumn({
            table: 'estrobos',
            column: 'ubicacion',
            id_interno: estrobo,
            newValue: ubicacion,
            action: 'update',
          });});
          let grilletes = oldGrilletes != newGrilletes ? oldGrilletes : newGrilletes
        await processEntries(grilletes, async (grillete) => {
          await updateColumn({
            table: 'grilletes',
            column: 'ubicacion',
            id_interno: grillete,
            newValue: ubicacion,
            action: 'update',
          });});

      });
    }

    console.log('Actualización completada con éxito.');
  } catch (error) {
    console.error('Error al actualizar los datos:', error.message);
  }
};

// Función para eliminar datos
export const deleteData = async (table) => {
  try {
    await updateColumn({
      table: table.table, // Asegúrate de acceder correctamente a la propiedad 'table' del objeto JSON
      column: table.column, // Asegúrate de que el JSON tenga esta propiedad si es necesaria
      id_interno: table.id_interno, // Asegúrate de que el JSON tenga esta propiedad si es necesaria
      action: 'delete',
    });
    if(table.column != 'cliente'){
      await updateColumn({
        table: table.table, // Asegúrate de acceder correctamente a la propiedad 'table' del objeto JSON
        column: 'ubicacion', // Asegúrate de que el JSON tenga esta propiedad si es necesaria
        id_interno: table.id_interno, // Asegúrate de que el JSON tenga esta propiedad si es necesaria
        action: 'delete',
      });
    }
    if(table.estrobo){
      await processEntries(table.estrobo, async (estrobo) => {
        await updateColumn({
          table: 'estrobos', // Asegúrate de acceder correctamente a la propiedad 'table' del objeto JSON
          column: 'ubicacion', // Asegúrate de que el JSON tenga esta propiedad si es necesaria
          id_interno: estrobo, // Asegúrate de que el JSON tenga esta propiedad si es necesaria
          action: 'delete',
        });
      });
    }
    if(table.grilletes){
      await processEntries(table.grilletes, async (grillete) => {
        await updateColumn({
          table: 'grilletes', // Asegúrate de acceder correctamente a la propiedad 'table' del objeto JSON
          column: 'ubicacion', // Asegúrate de que el JSON tenga esta propiedad si es necesaria
          id_interno: grillete, // Asegúrate de que el JSON tenga esta propiedad si es necesaria
          action: 'delete',
        });
      });
    }

    console.log('Datos eliminados con éxito.');
  } catch (error) {
    console.error('Error al eliminar los datos:', error.message);
  }
};

// Función de utilidad para procesar arrays o valores únicos
export const processEntries = async (entries, callback) => {
  if (Array.isArray(entries)) {
    for (const entry of entries) {
      await callback(entry);
    }
  } else if (entries) {
    await callback(entries);
  }
};

// Función base para actualizar columnas
const updateColumn = async ({ table, column, id_interno, newValue, action }) => {
  const url = 'https://api.bhsmx.com/api/update-column'; // URL del endpoint

  try {
    const response = await fetch(url, {
      method: 'PUT', // Método PUT para actualizaciones
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        table,
        column,
        id_interno,
        newValue,
        action,
      }), // Datos de la solicitud
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || 'Error al actualizar la columna.');
    }

    console.log('Columna actualizada con éxito:', data.message);
    return data; // Devuelve el resultado en caso de éxito
  } catch (error) {
    console.error('Error al actualizar la columna:', error.message);
    return null; // Manejo de errores
  }
};
