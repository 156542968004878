import React from "react";
import './MainPage.css';
import GaleryViewer from "./GaleryViewer.js";
import TwoSidesContent from "./TwoSidesContent.js";
import CompaniesCarousel from "./CompaniesCarousel.js";
import MisionContent from "./MisionContent.js";

import Image1 from './assets/img/M-L03.jpg';
import Image2 from './assets/img/M-G011.jpg';
import Image3 from './assets/img/M-G006.jpg';
import Image4 from './assets/img/BEC-001.jpg';
import Image6 from './assets/img/VDF-020.jpg';
import Image7 from './assets/img/M-G010.jpg';
import Image8 from './assets/img/M-G037.jpg';
import Image9 from './assets/img/M-G067.jpg';
import Image10 from './assets/img/SP-005.jpg';

import FSimage from './assets/img/TS-005.jpg';
import TS004 from './assets/img/TS-004.jpg';
import TS006 from './assets/img/TS-006.jpg';
import TS008 from './assets/img/TS-008.jpg';
import TS010 from './assets/img/TS-010.jpg';
import TS014 from './assets/img/TS-014.jpg';
import TS015 from './assets/img/TS-015.jpg';
import TS016 from './assets/img/TS-016.jpg';
import TS017 from './assets/img/TS-017.jpg';

import company1 from './assets/img/IMG-C-01.jpg';
import company2 from './assets/img/IMG-C-02.jpg';
import company3 from './assets/img/IMG-C-03.jpg';
import company4 from './assets/img/IMG-C-04.png';
import company5 from './assets/img/IMG-C-05.jpg';
import company6 from './assets/img/IMG-C-06.jpg';
import company7 from './assets/img/IMG-C-07.jpg';
import company8 from './assets/img/IMG-C-08.jpg';  
import company9 from './assets/img/IMG-C-09.png';
import company10 from './assets/img/IMG-C-10.jpg';
import company11 from './assets/img/IMG-C-11.jpg';
import company12 from './assets/img/IMG-C-12.png';
import company13 from './assets/img/IMG-C-13.jpg';
import company14 from './assets/img/IMG-C-14.png';
import company16 from './assets/img/IMG-C-16.jpg';
import company17 from './assets/img/IMG-C-17.jpg';

import JSONGVimg from './data/GVimg.json'
import JSONTSCimg from './data/TSCimg.json'
import JSONTSCimg2 from './data/TSCimg2.json'

const imageMap = {
    "FSimage": FSimage,
    "TS-004":TS004,
    "TS-006":TS006,
    "TS-008":TS008,
    "TS-010":TS010,
    "TS-014":TS014,
    "TS-015":TS015,
    "TS-016":TS016,
    "TS-017":TS017,
  };

  const scrMap={
    "Image1": Image1,
    "Image2": Image2,
    "Image3": Image3,
    "Image4": Image4,
    "Image6" : Image6,
    "Image7" : Image7,
    "Image8" : Image8,
    "Image9" : Image9,
    "Image10" : Image10
  };
  
  const GVimg = JSONGVimg.map(item => ({
    ...item,
    src: scrMap[item.src]  // Asigna la imagen correspondiente
  }));;

  const TSCimg=JSONTSCimg.map(item => ({
    ...item,
    src: imageMap[item.src]  // Asigna la imagen correspondiente
  }));;

  const TSCimg2=JSONTSCimg2.map(item => ({
    ...item,
    src: imageMap[item.src]  // Asigna la imagen correspondiente
  }));;

const imagesCompanies = [
    company1, company2, company3, company4, company5, company6, 
    company7, company8, company9, company10, company11, company12,
    company13, company14, company16, company17
  ];

const MainPage = () => {
return(
    <div className="Container-Content"> 
        <div className="M-First-Content">
            <GaleryViewer images={GVimg} />
        </div>
        <div className="M-Second-Content">
          <TwoSidesContent 
          images1 = {TSCimg}
          images2 = {TSCimg2}
        
        />
        </div>
        <div className="M-Third-Content">
        <MisionContent/>
        </div>
        <div className="M-Fourth-Content">
        <CompaniesCarousel images={imagesCompanies}/>
        </div>
    </div>
)
}

export default MainPage;
