const sortData = (data, setData, sortConfig, setSortConfig, key, type) => {
  const direction =
    sortConfig.key === key && sortConfig.direction === 'ascending'
      ? 'descending'
      : 'ascending';

  const sortedData = [...data].sort((a, b) => {
    if (a[key] == null || b[key] == null) return a[key] == null ? 1 : -1;

    switch (type) {
      case 'IsDate':
        return direction === 'ascending'
          ? new Date(a[key]) - new Date(b[key])
          : new Date(b[key]) - new Date(a[key]);
      case 'IsNumeric':
        return direction === 'ascending'
          ? parseFloat(a[key]) - parseFloat(b[key])
          : parseFloat(b[key]) - parseFloat(a[key]);
      case 'IsBoolean':
        const boolA = a[key] ? 1 : 0;
        const boolB = b[key] ? 1 : 0;
        return direction === 'ascending' ? boolA - boolB : boolB - boolA;
      default:
        return direction === 'ascending'
          ? a[key].localeCompare(b[key])
          : b[key].localeCompare(a[key]);
    }
  });

  setData(sortedData);
  setSortConfig({ key, direction });
};

export default sortData;
