import React, { useState } from 'react';
import axios from 'axios';

const Register = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [telefono, setTelefono] = useState('');
  const [nombre, setNombre] = useState('');
  const [message, setMessage] = useState('');

  const handleRegister = async (e) => {
    e.preventDefault();
    
    try {
      const response = await axios.post('https://api.bhsmx.com/api/register', {
        username,
        password,
        email,
        telefono,
        nombre
      });
      setMessage(response.data.message);
      setUsername('');
      setPassword('');
      setEmail('');
      setTelefono('');
      setNombre('');
    } catch (error) {
      setMessage(error.response ? error.response.data.message : 'Error al registrarse');
    }
  };

  return (
    <div className="login-Container">
        <div className="Main-Login-Container">
            <h2>Registro de Usuario</h2>
            <form onSubmit={handleRegister}>
                <div className="username">
                <input 
                    type="text" 
                    value={username} 
                    onChange={(e) => setUsername(e.target.value)} 
                    required 
                />
                <label>Nombre de Usuario:</label>
                </div>
                <div className="password">
                <input 
                    type="password" 
                    value={password} 
                    onChange={(e) => setPassword(e.target.value)} 
                    required 
                />
                <label>Contraseña:</label>
                </div>
                <div className="email">
                <input 
                    type="email" 
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)} 
                    required 
                />
                <label>e-mail:</label>
                </div>
                <div className="telefono">
                <input 
                    type="text" 
                    value={telefono} 
                    onChange={(e) => setTelefono(e.target.value)} 
                    required 
                />
                <label>Telefono:</label>
                </div>
                <div className="nombre">
                <input 
                    type="text" 
                    value={nombre} 
                    onChange={(e) => setNombre(e.target.value)} 
                    required 
                />
                <label>Nombre:</label>
                </div>
                <button className="submit-login" type="submit">Registrar</button>
            </form>
           <div className="error">{message}</div>
            </div>
    </div>
    
  );
};

export default Register;
