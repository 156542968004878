const RacksTable = ({
    data,
    sortData,
    sortConfig,
    setData,
    setSortConfig,
    formatFecha,
    getSortIcon,
    renderSortButton,
    DownloadButton,
    getBackgroundColor
  }) => {

    const createSortButtonParams = (columnKey, label, type) => ({
        columnKey,
        label,
        data: data,
        setData: setData,
        sortConfig: sortConfig,
        setSortConfig: setSortConfig,
        sortData:sortData,
        getSortIcon:getSortIcon,
        type
      });
  
    return (
      <>
        <h2>Tabla de Racks de Transporte</h2>
        <table className="content-table">
          <thead>
            <tr>
              <th rowSpan="2">Tag{renderSortButton(createSortButtonParams('id_interno', 'Tag'))}</th>
              <th colSpan="3">PND</th>
              <th rowSpan="2">Ubicacion{renderSortButton(createSortButtonParams('ubicacion', 'Ubicacion'))}</th>
              <th rowSpan="2">PDF</th>
            </tr>
            <tr>
              <th>Última{renderSortButton(createSortButtonParams('certificacion', 'Tag', 'IsDate'))}</th>
              <th>Siguientes{renderSortButton(createSortButtonParams('next_certificacion', 'Tag', 'IsDate'))}</th>
              <th>Faltan{renderSortButton(createSortButtonParams('dias_restantes_1', 'Tag', 'IsNumeric'))}</th>
            </tr>
          </thead>
          <tbody>
            {data.map((rack, index) => (
              <tr key={index}>
                <td data-label="Tag">{rack.id_interno || 'N/A'}</td>
                <td data-label="Ultima PND">
                  {rack.certificacion ? formatFecha(rack.certificacion) : 'Sin datos'}
                </td>
                <td data-label="Siguiente PND">
                  {rack.next_certificacion ? formatFecha(rack.next_certificacion) : 'Sin datos'}
                </td>
                <td
                  data-label="Dias faltantes"
                  style={{
                    backgroundColor: getBackgroundColor(rack.dias_restantes_1),
                    color: 'white',
                  }}
                >
                  {rack.dias_restantes_1 || 'N/A'} d
                </td>
                <td data-label="Ubicación">{rack.ubicacion || 'Sin datos'}</td>
                <td data-label="PDF">
                <DownloadButton table="racks" tag={rack.id_interno} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  };
  
  export default RacksTable;
  