import React from 'react';
import { Link } from 'react-router-dom';
import './AdminDashboard.css';

const fetchAdminData = async () => {
    const token = localStorage.getItem('token'); // Obtén el token del localStorage
  
    if (!token) {
      console.error('Token no encontrado. Inicia sesión primero.');
      return;
    }
  
    try {
      const response = await fetch('https://api.bhsmx.com/api/admin', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`, // Incluye el token en el encabezado de autorización
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log('Datos de administrador:', data);
      } else {
        const errorData = await response.json();
        console.error('Error al acceder a la API:', errorData.message);
        if (response.status === 401 || response.status === 403) {
          // Si el token está vencido o no autorizado
          console.log('Redirigiendo al login...');
          localStorage.removeItem('token'); // Elimina el token vencido
          window.location.href = '/login'; // Redirige al login
        }
      }
    } catch (error) {
      console.error('Error de red:', error);
    }
  };
  
  // Llamar a la función para probar
  fetchAdminData();
  

const AdminDashboard = () => {
    return (
        <div className="AdminDashboard-Container">
            <h2>Interfaz Admin</h2>
            <div className="AD-Wrapper">
                <button className="AD-btn">
                    <Link to="/register" className="Linkto">Registrar Usuario</Link>
                </button>
                <button className="AD-btn">
                    <Link to="/certificados" className="Linkto">Certificados</Link>
                </button>
                <button className="AD-btn">
                    <Link to="/re-cer" className="Linkto">Editar Certificados</Link>
                </button>
            </div>
        </div>
    );
};

export default AdminDashboard;

