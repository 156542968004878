const CanastillasTable = ({
    data,
    sortData,
    sortConfig,
    setData,
    setSortConfig,
    formatFecha,
    getSortIcon,
    renderSortButton,
    DownloadButton,
    getBackgroundColor
  }) => {
    const createSortButtonParams = (columnKey, label, type) => ({
        columnKey,
        label,
        data: data,
        setData: setData,
        sortConfig: sortConfig,
        setSortConfig: setSortConfig,
        sortData:sortData,
        getSortIcon:getSortIcon,
        type
      });
  
    return (
      <>
        <h2>Tabla de Canastillas de Transporte</h2>
        <table className="content-table">
          <thead>
            <tr>
              <th rowSpan="2">Tag{renderSortButton(createSortButtonParams('id_interno', 'Tag'))}</th>
              <th rowSpan="2">Dimensiones</th>
              <th colSpan="3">Prueba de Carga</th>
              <th colSpan="3">PND</th>
              <th rowSpan="2">Ubicacion{renderSortButton(createSortButtonParams('ubicacion', 'Ubicacion'))}</th>
              <th rowSpan="2">PDF</th>
            </tr>
            <tr>
              <th>Última{renderSortButton(createSortButtonParams('carga_fecha', 'Tag', 'IsDate'))}</th>
              <th>Siguiente{renderSortButton(createSortButtonParams('carga_next', 'Tag', 'IsDate'))}</th>
              <th>Faltan{renderSortButton(createSortButtonParams('dias_restantes_2', 'Tag','IsNumeric'))}</th>
              <th>Última{renderSortButton(createSortButtonParams('pnd_fecha', 'Tag', 'IsDate'))}</th>
              <th>Siguientes{renderSortButton(createSortButtonParams('pnd_next', 'Tag', 'IsDate'))}</th>
              <th>Faltan{renderSortButton(createSortButtonParams('dias_restantes_2', 'Tag', 'IsNumeric'))}</th>
            </tr>
          </thead>
          <tbody>
            {data.map((canastillas, index) => (
              <tr key={index}>
                <td data-label="Tag">{canastillas.id_interno || 'N/A'}</td>
                <td data-label="dimensiones">{canastillas.dimensiones || 'N/A'}</td>
                <td data-label="Ultima prueba de carga">
                  {canastillas.carga_fecha ? formatFecha(canastillas.carga_fecha) : 'Sin datos'}
                </td>
                <td data-label="Siguiente prueba de carga">
                  {canastillas.carga_next ? formatFecha(canastillas.carga_next) : 'Sin datos'}
                </td>
                <td
                  data-label="Dias faltantes"
                  style={{
                    backgroundColor: getBackgroundColor(canastillas.dias_restantes_1),
                    color: 'white',
                  }}
                >
                  {canastillas.dias_restantes_1 || 'N/A'} d
                </td>
                <td data-label="Ultima PND">
                  {canastillas.pnd_fecha ? formatFecha(canastillas.pnd_fecha) : 'Sin datos'}
                </td>
                <td data-label="Siguiente PND">
                  {canastillas.pnd_next ? formatFecha(canastillas.pnd_next) : 'Sin datos'}
                </td>
                <td
                  data-label="Dias faltantes"
                  style={{
                    backgroundColor: getBackgroundColor(canastillas.dias_restantes_2),
                    color: 'white',
                  }}
                >
                  {canastillas.dias_restantes_2 || 'N/A'} d
                </td>
                <td data-label="Ubicación">{canastillas.ubicacion || 'Sin datos'}</td>
                <td data-label="PDF">
                <DownloadButton table="canastillas" tag={canastillas.id_interno} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
    </>
    );
  };
  
  export default CanastillasTable;