import React from 'react';
import { Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; // Importación corregida

const ProtectedRoute = ({ children, allowedTypes }) => {
  const token = localStorage.getItem('token'); // Obtener el token del localStorage

  // Si no hay token, redirigir a login
  if (!token) {
    return <Navigate to="/login" />;
  }

  try {
    const decodedToken = jwtDecode(token); // Decodificar el token
    const userType = decodedToken.type; // Obtener el tipo de usuario del token

    // Si el tipo de usuario no está permitido, redirige
    if (!allowedTypes.includes(userType)) {
      return <Navigate to="/login" />;
    }
  } catch (error) {
    return <Navigate to="/login" />;
  }

  return children; // Permite que el usuario acceda a la ruta protegida
};

export default ProtectedRoute;
