import React, { useState } from 'react';
import './ContentBoxFourSides.css';
import { Link } from 'react-router-dom';



function ContentBoxFourSides({imgs}) {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className="Container-ContentBoxFourSides">
      <div className='Container-BFS-Wrapper'>
        <div className="Container-BFS-A">
          {imgs.map((item, index) => (
            <div
              key={index}
              className={`Container-BFSItemBox ${activeIndex !== null && activeIndex !== index ? 'hidden' : 'clicked'} ${activeIndex === index ? 'active' : ''}`}
              onClick={() => handleClick(index)}
            >
              <img src={item.img} alt={item.alt} className="BFSimg" />
              <h3>{item.alt}</h3>
            </div>
          ))}
        </div>
        {activeIndex !== null && (
          <div className="Container-BFS-B">
            <h2>{imgs[activeIndex].alt}</h2>

            <p dangerouslySetInnerHTML={{ __html: imgs[activeIndex].description }} />
            <div className='buttonwrapper'><Link to={imgs[activeIndex].page} className="Linkto"> <button className='vermasbtn'>Ver más</button></Link></div>
            
          </div>
        )}
      </div>
    </div>
  );
}

export default ContentBoxFourSides;
