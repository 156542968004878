const TorquimetrosTable = ({
    data,
    sortData,
    sortConfig,
    setData,
    setSortConfig,
    formatFecha,
    getSortIcon,
    renderSortButton,
    DownloadButton,
    getBackgroundColor
  }) => {

    const createSortButtonParams = (columnKey, label, type) => ({
        columnKey,
        label,
        data: data,
        setData: setData,
        sortConfig: sortConfig,
        setSortConfig: setSortConfig,
        sortData:sortData,
        getSortIcon:getSortIcon,
        type
      });
  
    return (
      <>
        <h2>Tabla de Torquimetros</h2>
        <table className="content-table">
          <thead>
            <tr>
              <th rowSpan="2">Tag{renderSortButton(createSortButtonParams('id_interno', 'Tag'))}</th>
              <th rowSpan="2">Descripción{renderSortButton(createSortButtonParams('descripcion', 'Descripción'))}</th>
              <th rowSpan="2">Marca{renderSortButton(createSortButtonParams('marca', 'Marca'))}</th>
              <th rowSpan="2">Modelo{renderSortButton(createSortButtonParams('modelo', 'Modelo'))}</th>
              <th rowSpan="2">Serie{renderSortButton(createSortButtonParams('serie', 'Serie'))}</th>
              <th colSpan="3">Calibración</th>
              <th rowSpan="2">PDF</th>
            </tr>
            <tr>
              <th>Última{renderSortButton(createSortButtonParams('certificacion', 'Tag', 'IsDate'))}</th>
              <th>Siguientes{renderSortButton(createSortButtonParams('next_certificacion', 'Tag', 'IsDate'))}</th>
              <th>Faltan{renderSortButton(createSortButtonParams('dias_restantes_1', 'Tag', 'IsNumeric'))}</th>
            </tr>
          </thead>
          <tbody>
            {data.map((torquimetro, index) => (
              <tr key={index}>
                <td data-label="Tag">{torquimetro.id_interno || 'N/A'}</td>
                <td data-label="Descripción">{torquimetro.descripcion || 'Sin datos'}</td>
                <td data-label="Marca">{torquimetro.marca || 'Sin datos'}</td>
                <td data-label="Modelo">{torquimetro.modelo || 'Sin datos'}</td>
                <td data-label="Serie">{torquimetro.serie || 'Sin datos'}</td>
                <td data-label="Ultima calibración">
                  {torquimetro.calibracion ? formatFecha(torquimetro.calibracion) : 'Sin datos'}
                </td>
                <td data-label="Siguiente calibración">
                  {torquimetro.calibracion_next ? formatFecha(torquimetro.calibracion_next) : 'Sin datos'}
                </td>
                <td
                  data-label="Dias faltantes"
                  style={{
                    backgroundColor: getBackgroundColor(torquimetro.dias_restantes_1),
                    color: 'white',
                  }}
                >
                  {torquimetro.dias_restantes_1 || 'N/A'} d
                </td>
                <td data-label="PDF">
                <DownloadButton table="torquimetros" tag={torquimetro.id_interno} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  };
  
  export default TorquimetrosTable;
  