import React, { useState, useEffect } from 'react';
import ClientTable from './api/Tablas/client';

// ICONS
import SortZa from './assets/icon/SortAz.svg';
import SortAz from './assets/icon/SortZa.svg';

// UTILITY FUNCTIONS
import sortData from './api/SortUtility.js'; // Función para ordenar datos
import renderSortButton from './api/renderSortButton'; // Función para renderizar botones de ordenamiento
import DownloadButton from './DownloadButton';

const fetchCertificationsWithDays = async (username, tables, dateFields) => {
  const url = 'https://api.bhsmx.com/api/get-certifications';

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, tables }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Error al obtener las certificaciones');
    }

    const data = await response.json();

    // Procesar los datos para calcular días restantes
    const processedData = data.map((item) => {
      const hoy = new Date();
      const daysRemaining = {};

      dateFields.forEach((field, index) => {
        if (item[field]) {
          const diferencia = new Date(item[field]) - hoy;
          daysRemaining[`dias_restantes_${index + 1}`] = Math.ceil(diferencia / (1000 * 60 * 60 * 24));
        } else {
          daysRemaining[`dias_restantes_${index + 1}`] = null;
        }
      });

      return { ...item, ...daysRemaining };
    });

    return processedData; // Retornar los datos procesados
  } catch (error) {
    console.error('Error en la solicitud:', error.message);
    return [];
  }
};

const ClientTables = (username, tables) => {
  const [client, setClient] = useState([]);
  const [sortConfigClient, setSortConfigClient] = useState({ key: null, direction: 'ascending' });

  useEffect(() => {
        const username = 'slb'; // Cambia según sea necesario
    const tables = ['spoolers', 'bml', 'canastillas', 'base_carrete']; // Tablas a consultar
    const dateFields = ['carga_next', 'pnd_next']; // Campos de fechas a procesar

    const fetchData = async () => {
      const data = await fetchCertificationsWithDays(username, tables, dateFields);
      setClient(data);
    };

    fetchData();
  }, []);

  // Función para obtener el icono de ordenamiento según la configuración
  const getSortIcon = (sortConfig, key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? SortAz : SortZa;
    }
    return SortAz; // Ícono por defecto
  };

  // Función para formatear la fecha en formato local
  const formatFecha = (fecha) => {
    const date = new Date(fecha);
    return date.toLocaleDateString(); // Devuelve solo la fecha
  };

  return (
    <div className="TablaEras-Container">
      <ClientTable
        data={client}
        sortConfig={sortConfigClient}
        setData={setClient}
        setSortConfig={setSortConfigClient}
        sortData={sortData}
        formatFecha={formatFecha}
        getSortIcon={getSortIcon}
        renderSortButton={renderSortButton}
        DownloadButton={DownloadButton}
      />
    </div>
  );
};

export default ClientTables;
