import React, { useState, useEffect, useRef } from "react";
import './ExpandibleCards.css';

import img1 from './assets/img/VDF-001.jpg';
import img3 from './assets/img/VDF-004.jpg';
import img4 from './assets/img/VDF-003.jpg';
import img5 from './assets/img/VDF-005.jpg';
import img6 from './assets/img/VDF-006.jpg';
import img7 from './assets/img/M-G006.jpg';
import img8 from './assets/img/M-G010.jpg';
import img9 from './assets/img/M-G011.jpg';
import img10 from './assets/img/M-G013.jpg';
import img11 from './assets/img/M-G014.jpg';
import img12 from './assets/img/M-L03.jpg';
import img13 from './assets/img/BEC-001.jpg';
import img14 from './assets/img/HPT-001.jpg';
import img15 from './assets/img/HPT-002.jpg';
import img16 from './assets/img/M-G019.jpg';
import img17 from './assets/img/M-G020.jpg';
import img18 from './assets/img/M-G021.jpg';
import img19 from './assets/img/M-G022.jpg';
import img20 from './assets/img/M-G023.jpg';
import img21 from './assets/img/M-G024.jpg';
import img22 from './assets/img/M-G025.jpg';
import img23 from './assets/img/M-G026.jpg';
import img24 from './assets/img/M-G027.jpg';
import img25 from './assets/img/M-G028.jpg';
import img26 from './assets/img/M-G029.jpg';
import img27 from './assets/img/M-G030.jpg';
import img28 from './assets/img/M-G031.jpg';
import img29 from './assets/img/M-G032.jpg';
import img30 from './assets/img/M-G033.jpg';
import img31 from './assets/img/M-G034.jpg';
import img32 from './assets/img/M-G035.jpg';
import img33 from './assets/img/M-G036.jpg';
import img34 from './assets/img/M-G037.jpg';
import img35 from './assets/img/M-G038.jpg';
import img36 from './assets/img/M-G039.jpg';
import img37 from './assets/img/M-G040.jpg';
import img38 from './assets/img/M-G041.jpg';
import img39 from './assets/img/M-G042.jpg';
import img40 from './assets/img/M-G043.jpg';
import img41 from './assets/img/M-G044.jpg';
import img42 from './assets/img/M-G045.jpg';
import img43 from './assets/img/M-G046.jpg';
import img44 from './assets/img/M-G051.jpg';
import img45 from './assets/img/M-G048.jpg';
import img46 from './assets/img/M-G052.jpg';
import img47 from './assets/img/M-G053.jpg';

const items = [
    { src: img1, title: 'Imagen 1' },
    { src: img3, title: 'Imagen 2' },
    { src: img4, title: 'Imagen 3' },
    { src: img5, title: 'Imagen 4' },
    { src: img6, title: 'Imagen 5' },
    { src: img7, title: 'Imagen 6' },
    { src: img8, title: 'Imagen 7' },
    { src: img9, title: 'Imagen 8' },
    { src: img10, title: 'Imagen 9' },
    { src: img11, title: 'Imagen 10' },
    { src: img12, title: 'Imagen 11' },
    { src: img13, title: 'Imagen 12' },
    { src: img14, title: 'Imagen 13' },
    { src: img15, title: 'Imagen 14' },
    { src: img16, title: 'Imagen 15' },
    { src: img17, title: 'Imagen 16' },
    { src: img18, title: 'Imagen 17' },
    { src: img19, title: 'Imagen 18' },
    { src: img20, title: 'Imagen 19' },
    { src: img21, title: 'Imagen 20' },
    { src: img22, title: 'Imagen 21' },
    { src: img23, title: 'Imagen 22' },
    { src: img24, title: 'Imagen 23' },
    { src: img25, title: 'Imagen 24' },
    { src: img26, title: 'Imagen 25' },
    { src: img27, title: 'Imagen 26' },
    { src: img28, title: 'Imagen 27' },
    { src: img29, title: 'Imagen 28' },
    { src: img30, title: 'Imagen 29' },
    { src: img31, title: 'Imagen 30' },
    { src: img32, title: 'Imagen 31' },
    { src: img33, title: 'Imagen 32' },
    { src: img34, title: 'Imagen 33' },
    { src: img35, title: 'Imagen 34' },
    { src: img36, title: 'Imagen 35' },
    { src: img37, title: 'Imagen 36' },
    { src: img38, title: 'Imagen 37' },
    { src: img39, title: 'Imagen 38' },
    { src: img40, title: 'Imagen 39' },
    { src: img41, title: 'Imagen 40' },
    { src: img42, title: 'Imagen 41' },
    { src: img43, title: 'Imagen 42' },
    { src: img44, title: 'Imagen 43' },
    { src: img45, title: 'Imagen 44' },
    { src: img46, title: 'Imagen 45' },
    { src: img47, title: 'Imagen 46' },
];



function ExpandibleCards() {
    const [currentImages, setCurrentImages] = useState([]);
    const [activeCard, setActiveCard] = useState(null);
    const [numCards, setNumCards] = useState(6); // Número de tarjetas visibles
    const timerRef = useRef(null);

    const getRandomImages = (count) => {
        const shuffled = [...items].sort(() => 0.5 - Math.random());
        return shuffled.slice(0, count);
    };

    // Ajusta el número de tarjetas visibles según el ancho de la pantalla
    const updateNumCards = () => {
        const width = window.innerWidth;
        if (width <= 480) {
            setNumCards(2); // Para móviles pequeños
        } else if (width <= 768) {
            setNumCards(3); // Para tablets
        } else {
            setNumCards(6); // Para pantallas más grandes
        }
    };

    useEffect(() => {
        updateNumCards();
        window.addEventListener("resize", updateNumCards);

        return () => window.removeEventListener("resize", updateNumCards);
    }, []);

    useEffect(() => {
        setCurrentImages(getRandomImages(numCards));

        const intervalId = setInterval(() => {
            setCurrentImages(getRandomImages(numCards));
        }, 5000);

        return () => clearInterval(intervalId);
    }, [numCards]);

    const handleMouseEnter = (index) => {
        clearTimeout(timerRef.current);
        setActiveCard(index);
    };

    const handleMouseLeave = () => {
        timerRef.current = setTimeout(() => {
            setActiveCard(null);
        }, 2000);
    };

    return (
        <div className="container-ExpandibleCards">
            {currentImages.map((item, index) => (
                <div
                    className={`Card ${activeCard === index ? 'expanded' : ''}`}
                    key={index}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                >
                    <img src={item.src} alt={`Imagen ${index + 1}`} className="Background" />
                    <div className="Card-Content">
                    </div>
                    <div className="Backdrop"></div>
                </div>
            ))}
        </div>
    );
}

export default ExpandibleCards;