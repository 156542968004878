import React from "react";
import './SistemasComunicacion.css';
import TopSelectorbySides from "./TopSelectorbySides";

import Pruebas from './data/ComunicacionPruebas.json';
import Instalacion from './data/ComunicacionInstalacion.json';

import CI001 from './assets/img/M-G065.jpg';
import CI002 from './assets/img/M-G066.jpg';
import CI003 from './assets/img/M-G067.jpg';
import CI004 from './assets/img/M-G068.jpg';
import PC001 from './assets/img/M-PC001.jpg';

const scrMap={
    "CI001" : CI001,
    "CI002" : CI002,
    "CI003" : CI003,
    "CI004" : CI004,
    "PC001" : PC001
  };

const dataInstalacion = Instalacion.map(item => ({
    ...item,
    // Verifica si 'img' es un array, de ser así, mapea cada imagen
    img: Array.isArray(item.img)
      ? item.img.map(imgKey => scrMap[imgKey])  // Mapea cada clave a su imagen correspondiente
      : scrMap[item.img]  // Si no es array, simplemente asigna la imagen correspondiente
  }));
  const dataPruebas = Pruebas.map(item => ({
    ...item,
    // Verifica si 'img' es un array, de ser así, mapea cada imagen
    img: Array.isArray(item.img)
      ? item.img.map(imgKey => scrMap[imgKey])  // Mapea cada clave a su imagen correspondiente
      : scrMap[item.img]  // Si no es array, simplemente asigna la imagen correspondiente
  }));

function SistemasComunicacion() {
  return (
        <div className="Container-SistemasComunicacion">
          <TopSelectorbySides
          title={"Instalación a Sistemas de Comunicación"}
          namesV={dataInstalacion}
        />
          <TopSelectorbySides
          title={"Pruebas a Sistemas de Comunicación"}
          namesV={dataPruebas}
        />
        
        </div>
  );
}

export default SistemasComunicacion;
