import React from "react";
import './ContainerSlider.css';

import DownIcon from './assets/icon/Down.svg';

function ContainerSlider({ titulo, sliderData, activeIndex, setActiveIndex, sliderId }) {

  // Función para manejar el clic en cada slider
  const handleToggle = (index) => {
    // Alterna el estado del slider activo globalmente
    const newIndex = `${sliderId}-${index}`;
    setActiveIndex(activeIndex === newIndex ? null : newIndex);
  };

  return (
    <div className='container-ContainerSlider'>
      <div className="title-CS-wrapper">
        <h2>{titulo}</h2>
      </div>
      {/* Mapeando los datos del slider */}
      {sliderData.map((slide, index) => (
        <div key={index} className="CS-SliderContainer">
          <div className="CS-Slider" onClick={() => handleToggle(index)}>
            <h3>{slide.subtitle}</h3>
            <img src={DownIcon} className="CS-iconbutton" alt="Icono de abrir"/>
          </div>
          {/* Mostrar u ocultar el BotContainer según el estado */}
          {activeIndex === `${sliderId}-${index}` && (
            <div className="CS-BotContainer">
              {/* Inyectar el contenido HTML de forma segura */}
              <p dangerouslySetInnerHTML={{ __html: slide.text }} />
              <img src={slide.img} alt={slide.alt} className="CS-img" />
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default ContainerSlider;
